// Root Reducer

import { combineReducers } from 'redux';
import newLeadReducers from './newLeadReducers';

export let rootReducer = combineReducers({
	newLead: newLeadReducers,
});

export default rootReducer;
