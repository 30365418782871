import Landing from '../views/Landing';
import ThankYou from '../views/ThankYou';

let routes = [
	// {
	// 	path: '/',
	// 	component: Landing,
	// 	layout: 'main',
	// },
	// {
	// 	path: '/bedankt',
	// 	component: ThankYou,
	// 	layout: 'main',
	// },
];
export default routes;
