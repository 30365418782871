import React from 'react';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import cardimg1 from '../../assets/img/Laminaat.png';
import cardimg2 from '../../assets/img/HPL_CPL.png';
import cardimg3 from '../../assets/img/Hout.png';
import cardimg4 from '../../assets/img/PVC.png';

function StairRenovation() {
	return (
		<div className='StairR py-5'>
			<Container lg='fluid'>
				<div className='extra_padding'>
					<Row>
						<Col xs='12' md='4'>
							<h2 className='StairR_heading'>
								Kosten traprenovatie
							</h2>
							<hr />
						</Col>
						<Col xs='12' md='8'>
							<p className='StairR_text font-weight-'>
								Helaas is het niet mogelijk om een exact
								kostenplaatje te geven van traprenovatie. Dit
								komt doordat er veel bepalende factoren zijn.
								Denk hierbij aan het type trap, de gewenste
								trapbekleding, de hoeveelheid treden en de
								arbeidskosten.
								<br />
								<br />
								Om je een indicatie te geven van de kosten, vind
								je hieronder een tabel met daarin de gemiddelde
								kosten voor een open trap inclusief btw en
								plaatsing.
							</p>
						</Col>
					</Row>

					<Row className='text-sm-left text-center py-4 '>
						<Col xs='12' md='8' className='StairR_table'>
							<Table borderless responsive size='sm'>
								<thead>
									<tr>
										<th></th>
										<th className='border-bottom'>
											Gemiddelde kosten traprenovatie
										</th>
										<th className='border-bottom'>
											Prijs per trede*
										</th>
										<th className='border-bottom'>
											Prijs per trap*
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th scope='row' className='d-flex'>
											<img
												src={cardimg1}
												className='ml-auto StairR_table_img my-ms-0 my-auto'
											/>
										</th>
										<td>Laminaat</td>
										<td>€ 160</td>
										<td>vanaf € 2.500</td>
									</tr>
									<tr>
										<th scope='row' className='d-flex'>
											<img
												src={cardimg2}
												className='ml-auto StairR_table_img my-ms-0 my-auto'
											/>
										</th>
										<td>HPL /CPL</td>
										<td>€ 160</td>
										<td>vanaf € 2.500</td>
									</tr>
									<tr>
										<th scope='row' className='d-flex'>
											<img
												src={cardimg3}
												className='ml-auto StairR_table_img my-ms-0 my-auto'
											/>
										</th>
										<td>PVC (kunststof)</td>
										<td>€ 160</td>
										<td>vanaf € 2.500</td>
									</tr>
									<tr>
										<th scope='row' className='d-flex'>
											<img
												src={cardimg4}
												className='ml-auto StairR_table_img my-ms-0 my-auto'
											/>
										</th>
										<td>Hout</td>
										<td>€ 160</td>
										<td>vanaf € 2.500</td>
									</tr>
								</tbody>
							</Table>
						</Col>
						<Col
							xs='12'
							md='4'
							className='d-sm-flex d-block my-auto'
						>
							<div>
								<p className='StairR_text text-justify'>
									* Een trap heeft meestal 13 treden.
									Bovengenoemde prijzen gelden slechts als een
									indicatie. Om prijzen op maat te ontvangen
									van betrouwbare specialisten, raden wij je
									aan om een vrijblijvende offerteaanvraag
									doen.
								</p>
							</div>
						</Col>
					</Row>
					<h6 className='StairR_text text-center my-4'>
						Traprenovatie is maatwerk. Hierdoor verschillen de
						kosten per specialist aanzienlijk. Vergelijk daarom
						altijd meerdere offertes. Een offerteaanvraag via
						Offertevergelijker is gratis, vrijblijvend en helpt je
						bij het vinden van de beste deal voor jouw trap!
					</h6>
					<Button
						color='site-orange'
						className='px-3 py-2 mx-auto rounded-0 d-flex align-items-center'
						onClick={() => window.scrollTo(0, 180)}
					>
						Gratis offertes vergelijken
						<i class='fas fa-arrow-right ml-2'></i>
					</Button>
				</div>
			</Container>
		</div>
	);
}

export default StairRenovation;
